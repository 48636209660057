import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import HeroSection from './hero'
import ServicesSection from './service'
import CaseSection from './case'
import ContactSection from './contact'

const HeroContainer = styled(Container)`
  
`

const Home = () => {
  return (
    <HeroContainer fluid>
      <Row>
        <Col>
          <HeroSection/>
          <ServicesSection/>
          {/* <CaseSection /> */}
          <ContactSection/>
        </Col>
      </Row>
    </HeroContainer>
  )
}
export default Home
