import React from 'react'
import { Col, Row} from 'react-bootstrap'
import Styled from 'styled-components'
import '../../../../theme.scss'
import CaseImage1 from '../../../images/case_image1.png'
import CaseImage2 from '../../../images/case_image2.png'
import CaseImage3 from '../../../images/case_image3.png'
import { useStaticQuery,graphql } from "gatsby"


const DivCol = Styled(Col)`
  padding-right: 0px;
  padding-left: 0px;
`

const ServiceHeading = Styled.h2`
  font-family: Montserrat !important;
  color: #000 !important;
  font-weight: 800;

`

const SpanHeading = Styled.span`
  font-family: Montserrat !important;
  color: #5D58EA  !important;
  font-weight: 800;

`

const Paragraph = Styled.p`
  color: "#000 !important";
  width:50% !important;
  @media (max-width: 990px) {
    width:100% !important;
  }

  @media (max-width: 768px) {
    width:100% !important;
  }
`

const Paragrap1 = Styled.p`
  color: "#000 !important";
  width:100% !important;
  
`

const Paragraph2 = Styled.p`
  color: white !important;
  width:100% !important;
  
`

const Img = Styled.img`
  width:100%;
  height:100%
`

const DivImage = Styled.div`
  height:640px;
  @media (max-width: 990px) {
    height:500px;
  }

  @media (max-width: 768px) {
    height:350px;
  }
`

const DivBackground = Styled.div`
  background-color: #FFD245 !important;
  width:96%;
  height:50%;
  background-repeat: no-repeat;
  background-size: conatain;
  border-radius: 20px;
  margin-left: 12px;
  @media (max-width: 768px) {
    margin-left: 4px;
  }
`
const DivBackground2 = Styled.div`
  background-color: #B179F4 !important;
  width:96%;
  height:100%;
  background-repeat: no-repeat;
  background-size: conatain;
  border-radius: 15px;
  margin-left: 12px;
  @media (max-width: 768px) {
    margin-left: 4px;
  }
`

const SecondColParentDiv = Styled.div`
 height:640px;
 @media (max-width: 990px) {
  height:500px;
 }

  @media (max-width: 768px) {
    height:350px;
  }
`
const SecondColParentDiv2 = Styled.div`
 height:620px;
 @media (max-width: 990px) {
  height:500px;
}

@media (max-width: 768px) {
  height:350px;
}
`

const ThirdCol = Styled.div`
height:50% !important;
`

const ContentDiv = Styled.div`
  width: 70%

`

const CourseHeading = Styled.h3`
  font-family: Montserrat !important;
  color: #000 !important;
  font-weight: 800;

`

const CourseHeading2 = Styled.h3`
  font-family: Montserrat !important;
  color: white !important;
  font-weight: 800;

`




export default function Case() {
  const query = useStaticQuery(graphql`
  query CaseQuery {
    allWpPage(filter: {slug: {eq: "cases"}}) {
      edges {
        node {
          featuredImage {
            node {
              sourceUrl
            }
          }
          CaseSection{
            caseHeading
            caseHeadingSpecialKeyword
            caseDescription
            course{
              courseTitle
              courseDetail
            }
          }
        }
      }
    }
  }
  `)
  
  const CaseSection = query.allWpPage.edges[0].node.CaseSection;
  return (
    <>
    <section id="Cases">
    <Row>
      <Col>
        <div className="mt-5 mb-5 d-flex flex-column align-items-center">      
          <ServiceHeading>{CaseSection.caseHeading} <SpanHeading>{CaseSection.caseHeadingSpecialKeyword} </SpanHeading></ServiceHeading>
          <Paragraph className="text-center">Risus fermentum, eget dignissim ultrices odio lacinia bibendum eget. Donec sociis dui quis eros, cursus vestibulum nisi magna in.</Paragraph>
        </div>
      </Col>
    </Row>
    <Row className="d-flex align-items-center">
      <DivCol lg={6} md={6} sm={12} >
        <DivImage>
          <Img src={CaseImage1}/>
        </DivImage>
      </DivCol>
      <DivCol lg={6} md={6} sm={12} className="d-flex flex-column">
        <SecondColParentDiv>
          <DivBackground className="d-flex align-items-center justify-content-center">
            <ContentDiv>
              <CourseHeading className="text-center">{CaseSection.course[0].courseTitle}</CourseHeading>
              <Paragrap1 className="text-center">{CaseSection.course[0].courseDetail}</Paragrap1>
            </ContentDiv>
          </DivBackground>
          <ThirdCol>
            <Img src={CaseImage2}/>
          </ThirdCol>
        </SecondColParentDiv>
      </DivCol>
    </Row>
    <Row className="d-flex align-items-center">
      <DivCol lg={6} md={6} sm={12} className="d-flex flex-column">
        <SecondColParentDiv2>
          <DivBackground2 className="d-flex align-items-center justify-content-center">
            <ContentDiv>
              <CourseHeading2 className="text-center">{CaseSection?.course[1]?.courseTitle}</CourseHeading2>
              <Paragraph2 className="text-center">{CaseSection?.course[1]?.courseDetail}</Paragraph2>
            </ContentDiv>
          </DivBackground2>
        </SecondColParentDiv2>
      </DivCol>
      <DivCol lg={6} md={6} sm={12} >
        <DivImage>
          <Img src={CaseImage3}/>
        </DivImage>
      </DivCol>
    </Row>
    </section>
    </>
  )
}
