import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar,Nav, Row,Col} from 'react-bootstrap'
import Styled from 'styled-components'
import FooterLogo from '../../../images/logo2.svg'
import { useStaticQuery,graphql } from "gatsby"
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const FooterDiv = Styled.div`
  width:100%;
  background-color: #5D58EA !important;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
`

const ColDiv = Styled(Col)`

`

const SiteLogoFooter = Styled.img`
  width:83px;
  height:89px;

`


const StyledLink = Styled(AnchorLink)`
  font-weight: 300 !important;
  padding-left: 45px !important;
  color: white !important;
  @media (max-width: 768px) {
    padding-left: 0px !important;
    margin-bottom: 10px !important;
  }
`

const StyledNav = Styled(Nav)`
  @media (max-width: 768px) {
    flex-direction: column !important;
    text-align: center;
  }

`
const Span = Styled.span`
  font-size: 12px;
  color: white !important;
`
 
export default function Footer() {
  const query = useStaticQuery(graphql`
    query FooterMenu {
      allWpMenu {
        nodes {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    }
  `)
  const data = query.allWpMenu.nodes[0].menuItems.nodes;
  return (
    <>
    <Row className="mt-5">
      <FooterDiv>
        <Row>
          <ColDiv className="d-flex align-items-center justify-content-center mt-4 mb-4">
            <SiteLogoFooter src={FooterLogo}/>
          </ColDiv>
        </Row>
        <Row>
          <ColDiv className="d-flex align-items-center justify-content-center">
            <Navbar >
              <StyledNav >
                {data.map((menu, index) => (
                  <StyledLink key={index} to={`/#${menu.label.replace(/\s/g,'')}`}>{menu.label}</StyledLink>
                ))}
              </StyledNav>
            </Navbar>
          </ColDiv>
        </Row>
        <Row>
          <ColDiv className="d-flex align-items-center justify-content-center mt-4 mb-4">
            <Span>© 2020 Kuzmin Pty Ltd</Span>
          </ColDiv>
        </Row>
      </FooterDiv>
    </Row>
    </>
  )
}
