import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar,Nav,Row,Col} from 'react-bootstrap'
import Logo from '../../../images/logo.svg';
import Styled from 'styled-components'
import { useStaticQuery,graphql } from "gatsby"
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Burger from './burger'

const SiteLogo = Styled.img`
  width:83px;
`

const MainDiv = Styled.div`
@media only screen and (max-width: 990px) {
  .desktop-view{
    display:none !important;
  }
  .mobile-view{
    display:block !important;
  }
}
`

const MobileView = Styled.div`
  display:none !important;
`

const StyledLink = Styled(AnchorLink)`
  font-weight: 900 !important;
  padding-left: 45px !important;
  color: black !important;
`

SiteLogo.defaultProps = {
  src: Logo,
};


 
export default function Header() {
  const query = useStaticQuery(graphql`
    query MyQuery {
      allWpMenu {
        nodes {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    }
  `)

  const data = query.allWpMenu.nodes[0].menuItems.nodes;
  return (
    <>
      <section id="Home">
        <MainDiv>
          <div className="desktop-view">
          <Navbar expand="lg" variant="light" bg="white">
            <Navbar.Brand href="#"><SiteLogo /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                {data.map((menu, index) => (
                    <StyledLink key={index} to={`/#${menu.label.replace(/\s/g,'')}`}>{menu.label}</StyledLink>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <MobileView className="mobile-view">
          <Row>
            <Col>
              <Navbar className="bg-white d-flex flex-row" sticky="top">
              {/* <Navbar.Brand href="#"><SiteLogo /></Navbar.Brand> */}
                <div className="navheader-humburger d-flex flex-row justify-content-end">
                  <div className="humb">
                    <Navbar.Brand><Burger /></Navbar.Brand>
                  </div>
                </div>
              </Navbar>
            </Col>
          </Row>
          </MobileView>
        </MainDiv>
      </section>
    </>
  )
}
