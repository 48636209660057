import React from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import Styled from 'styled-components'
import HeroImage from '../../../images/hero-img.png'
import '../../../../theme.scss'
import { useStaticQuery,graphql } from "gatsby"
import { Link } from 'gatsby'

const HeadingCol = Styled(Col) `
  display: flex;
  align-items:center;
`

const Img = Styled.img`
  width:100%;
  margin-top: -80px;
  @media (max-width: 990px) {
    margin-top: 0px !important;
  }
`

const HeroHeading = Styled.h1`
  color: "#000 !important";
`
const SpanHeading = Styled.span`
  color: #5D58EA  !important;
`

const Paragraph = Styled.p`
  color: "#000 !important";
`
const HeroButton = Styled(Button)`
  font-family: MontserratSemiBold
  padding: 5px 12px;
  color: white;
  font-size: 18px;
  font-weight: 300;
  background-color:#5D58EA !important;
  border: 0px;
  border-radius: 20px;
  appearance: none;
  cursor: pointer;
  padding:10px 30px 10px 30px;
  @media (max-width: 768px) {
    width:100% !important;
  }
`;

Img.defaultProps = {
  src: HeroImage,
};




export default function Hero() {

  const query = useStaticQuery(graphql`
    query myQueryAndMyQuery  {
      allWpPage(filter: {slug: {eq: "home"}}) {
        edges {
          node {
            featuredImage{
              node{
                sourceUrl
              }
            }
            Herosection {
              heroTitle
              heroTitleSpecialKeyword
              heroDescription
            }
          }
        }
      }
    }
  `)
  const data = query.allWpPage.edges[0].node;

  return (
    <>
    <Row className="flex-column-reverse flex-md-row">
      <HeadingCol md={6}>
        <div className="order-sm-2">      
        <HeroHeading> {data.Herosection.heroTitle} <SpanHeading>{data.Herosection.heroTitleSpecialKeyword} </SpanHeading></HeroHeading>
        <Paragraph>{data.Herosection.heroDescription}</Paragraph>
        <Link to="/#ContactUs"><HeroButton>Contact Us</HeroButton></Link>
        </div>
      </HeadingCol>
      <Col md={6} className="order-sm-1">
        <Img/>
      </Col>
    </Row>
    </>
  )
}
 
