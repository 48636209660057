import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseLayout from './base-layout'
import Header from '../header/header'
import Footer from '../footer/footer'
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../theme.scss"

const Container = styled.div`
  margin: 0 auto;
  padding: 10px 40px 10px 40px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1400px;
`
const Content = styled.div`
  flex-grow: 1;
`
const Layout = ({ children }) => {
  
  return (
    <>
      <BaseLayout>
        <Container>
          <Content>
            <Header siteTitle={"title"} />
            {children}
          </Content>
        </Container>
      </BaseLayout>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
