import React from 'react'
import { Col, Row, Card, CardImg } from 'react-bootstrap'
import Styled from 'styled-components'
import '../../../../theme.scss'
import { useStaticQuery,graphql } from "gatsby"

const ServiceHeading = Styled.h2`
  font-family: Montserrat !important;
  color: #000 !important;
  font-weight: 800;

`

const SpanHeading = Styled.span`
  font-family: Montserrat !important;
  color: #5D58EA  !important;
  font-weight: 800;

`

const Paragraph = Styled.p`
  color: "#000 !important";
  width:45% !important;
  @media (max-width: 990px) {
    width:70% !important;
  }

  @media (max-width: 768px) {
    width:100% !important;
  }

`

const ServicesCard = Styled(Card)`
  border:1;
  border-radius: 1.25rem;
`

const ServicesCardImg = Styled(CardImg)`
  width:50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:15px;
  
`

const ServicesCardText = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ServicesCardParagraph = Styled.p`
  width:100% !important;
  min-height: 110px;

  @media (max-width: 990px) {
    width:100% !important;
  }
  @media (max-width: 768px) {
    width:100% !important;
  }
`

const CardCol = Styled(Col)`
  display:flex;
  align-items:center;
  justify-content:center;
  @media (max-width: 768px) {
    margin-top:10px;
  }
 
`
const CardDiv = Styled.div`
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  -webkit-box-shadow: rgba(206, 205, 250, 1)  1px 1px 5px;
  -moz-box-shadow: rgba(206, 205, 250, 1)  1px 1px 5px;
  box-shadow: rgba(206, 205, 250, 1)  1px 1px 5px;
  
  @media (max-width: 768px) {
    width:100% !important;
  }
`




export default function Services() {
  const query = useStaticQuery(graphql`
    query ServicesQuery  {
      allWpPage(filter: {slug: {eq: "service"}}) {
        edges {
          node {
            featuredImage {
              node {
                sourceUrl
              }
            }
            ServiceSection {
              serviceHeading
              serviceSpecialKeyword
              serviceHeadingDescription
              service {
                servicetitle
                servicedescription
                image {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `)
  
  const ServiceSection = query.allWpPage.edges[0].node.ServiceSection;
  return (
    <>
    <section id="Service">
    <Row>
      <Col>
        <div className="mt-5 mb-5 d-flex flex-column align-items-center">      
          <ServiceHeading>{ServiceSection.serviceHeading} <SpanHeading>{ServiceSection.serviceSpecialKeyword}</SpanHeading></ServiceHeading>
          <Paragraph className="text-center">{ServiceSection.serviceHeadingDescription}</Paragraph>
        </div>
      </Col>
    </Row>
    <Row className="d-flex align-items-center justify-content-center">
      {ServiceSection.service.map((item, index) => (
      <CardCol key={index} md={4} lg={4} className="mb-5" >
        <CardDiv>
          <ServicesCard>
            {(function() {
              if (index === 0) {
                return  <ServicesCardImg variant="top" src={item.image.sourceUrl} style={{"width" : "64%"}}/>
              } else {
                return  <ServicesCardImg variant="top" src={item.image.sourceUrl}/>
              }
            })()}
            <ServicesCard.Body>
              <ServicesCard.Title className="m-3 text-center"><SpanHeading>{item.servicetitle} </SpanHeading></ServicesCard.Title>
              <ServicesCardText >
                <ServicesCardParagraph className="text-center">{item.servicedescription}</ServicesCardParagraph>
              </ServicesCardText>
            </ServicesCard.Body>
          </ServicesCard>
        </CardDiv>
      </CardCol>
      ))}
    </Row>
    </section>
    </>
  )
}
