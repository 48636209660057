import React,{useState} from 'react'
import { Col, Row, Form, Button, Alert} from 'react-bootstrap'
import Styled from 'styled-components'
import '../../../../theme.scss'
import { useStaticQuery,graphql } from "gatsby"
import axios from "axios";

const ServiceHeading = Styled.h2`
  font-family: Montserrat !important;
  color: #000 !important;
  font-weight: 800;

`

const SpanHeading = Styled.span`
  font-family: Montserrat !important;
  color: #5D58EA  !important;
  font-weight: 800;

`

const Paragraph = Styled.p`
  color: "#000 !important";
  width:50% !important;
  text-align: center;
  @media (max-width: 990px) {
    width:70% !important;
  }

  @media (max-width: 768px) {
    width:100% !important;
  }
`

const FormButton = Styled(Button)`
  background-color: #5D58EA;
  border-radius: 20px;
  width: 25%;
  padding:10px 30px 10px 30px;
  @media (max-width: 990px) {
    width:70% !important;
  }
  @media (max-width: 768px) {
    width:100% !important;
  }

`

const ContactForm = Styled(Form)`
  width: 80%;
  @media (max-width: 768px) {
    width:100% !important;
  }
`

const FormInput = Styled(ContactForm.Control)`
    border: 1px solid #a8a6f7;
    border-radius: 10px;
    height: calc(1.5em + .75rem + 18px);
    ::placeholder { 
      color: #a8a6f7;
      opacity: 1;
    }
    
    :-ms-input-placeholder {
      color: #a8a6f7;
    }
    
    ::-ms-input-placeholder {
      color: #a8a6f7;
    }
`

const FormTextArea = Styled(ContactForm.Control)`
    width: 100%;
    border: 1px solid #a8a6f7;
    border-radius: 10px;
    padding: .375rem .75rem;
    &:input:focus {
      outline: none;
      box-shadow: 0px 0px 2px #a8a6f7;
  }
    ::placeholder { 
      color: #a8a6f7;
      opacity: 1;
    }
    
    :-ms-input-placeholder {
      color: #a8a6f7;
    }
    
    ::-ms-input-placeholder {
      color: #a8a6f7;
    }
`







export default function Contact() {
  
  const query = useStaticQuery(graphql`
  query ContactQuery {
    allWpPage(filter: {slug: {eq: "contact-us"}}) {
      edges {
        node {
          featuredImage {
            node {
              sourceUrl
            }
          }
          ContactSection {
            contactTitle
            contacrSpecialKeyword
            contactDescription
          }
        }
      }
    }
  }
  
  `)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://contentapi.kuzmin.com.au/wp-json/contact-form-7/v1/contact-forms/108/feedback",
      data: new FormData(form)
    })
      .then(r => {
        if(r.data.status === "mail_sent"){
          handleServerResponse(true, r.data.message, form);
        }else{
          handleServerResponse(false, r.data.message, form);
        }
      })
      // .catch(r => {
      //   handleServerResponse(false, r.response.data.error, form);
      // });
  };
  
  const CaseSection = query.allWpPage.edges[0].node.ContactSection;
  return (
    <>
    <section id="ContactUs">
      <Row>
        <Col>
          <div className="mt-5 d-flex flex-column align-items-center">      
            <ServiceHeading><SpanHeading>{CaseSection.contacrSpecialKeyword}</SpanHeading>{CaseSection.contactTitle}</ServiceHeading>
            <Paragraph>{CaseSection.contactDescription}</Paragraph>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 d-flex align-items-center justify-content-center">
        <ContactForm onSubmit={handleOnSubmit}>
          {serverState.status && (
              <Alert variant={!serverState.status.ok ? "danger" : "success"}>{serverState.status.msg}</Alert>
          )}
          <ContactForm.Row>
            <Col md={6} lg={6} sm={12}>
            <ContactForm.Group  controlId="formGridName">
              <FormInput type="text" name="your-name" placeholder="Enter your name" />
            </ContactForm.Group>
            </Col>
            <Col md={6} lg={6} sm={12}>
            <ContactForm.Group  controlId="formGridEmail">
              <FormInput type="text" name="your-email" placeholder="Enter your email"/>
            </ContactForm.Group>
            </Col>
          </ContactForm.Row>
          <ContactForm.Row>
          <ContactForm.Group as={Col} controlId="exampleForm.ControlTextarea1">
              <FormTextArea as={"textarea"} name="your-message" rows={6} placeholder="Enter your message" />
            </ContactForm.Group>
          </ContactForm.Row>
          <ContactForm.Group as={Col} className="d-flex flex-column align-items-center justify-content-center">
            <FormButton variant="primary" type="submit">
              Send
            </FormButton>
          </ContactForm.Group>
        </ContactForm>
      </Row>
      </section>
    </>
  )
}
