import React from 'react'
import styled from 'styled-components'
import { useStaticQuery,graphql } from "gatsby"
import { AnchorLink  } from 'gatsby-plugin-anchor-links'
import Styled from 'styled-components'

const Ul = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  display:none;
  flex-flow: row nowrap;
  padding:0px;
  box-shadow: rgba(206, 205, 250, 1)  1px 1px 5px;

  li {
    padding: 10px 0px;
  }

  @media (max-width: 990px) {
    display: flex;
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    top: 0;
    left: 0;
    height: 30;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    z-index: -1;
    padding-top: 3rem;
    padding-bottom: 2rem;

    li {
        font-family: 'Bw Glenn Sans';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.72;
        letter-spacing: -1.06px;
        text-align: center;
        color: #3b4d5a;
    }
  }
`

const StyledLink = Styled(AnchorLink)`
  font-weight: 900 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  color: #5D58EA !important;
`
export default function Header({ open, onCloseClick }) {

  
  const query = useStaticQuery(graphql`
    query MobileMenu {
      allWpMenu {
        nodes {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    }
  `)

  const data = query.allWpMenu.nodes[0].menuItems.nodes;

  return (
    <>
      <Ul open={open}>
        {data.map((menu, index) => (
          <StyledLink key={index} to={`/#${menu.label.replace(/\s/g,'')}`} onAnchorLinkClick={onCloseClick}>{menu.label}</StyledLink>
        ))}
      </Ul>
    </>
  )
}

